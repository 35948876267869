var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _vm._m(0),
    _c("div", { staticClass: "section-content" }, [
      _c(
        "div",
        { staticClass: "list-container" },
        [
          _c(
            "div",
            [
              _c("loading", {
                attrs: {
                  active: _vm.isLoading,
                  "can-cancel": false,
                  "is-full-page": _vm.fullPage,
                },
                on: {
                  "update:active": function ($event) {
                    _vm.isLoading = $event
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "b-card",
            { staticClass: "card-text" },
            [
              !_vm.isEditing
                ? _c("b-card-text", { staticClass: "card-header" }, [
                    _vm._v(" Bem vindo à conta corporativa Retextoar."),
                    _c("br"),
                    _vm._v(
                      " Cadastre os dados da sua organização para começar a criar grupos de trabalho e revolucionar a forma como você transcreve seus conteúdos. "
                    ),
                  ])
                : _vm._e(),
              _c("b-card-text", { staticClass: "card-label" }, [
                _vm._v(" Cadastro "),
              ]),
              _c(
                "div",
                { staticClass: "form-style" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "* CNPJ:",
                        "label-for": "nested-document",
                        "label-cols-sm": "2",
                        "label-align-sm": "left",
                        "content-cols-lg": "4",
                        state: _vm.validates.document,
                        "invalid-feedback": _vm.invalidFeedbackDocument,
                      },
                    },
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: ["##.###.###/####-##"],
                            expression: "['##.###.###/####-##']",
                          },
                        ],
                        attrs: { id: "nested-document" },
                        model: {
                          value: _vm.corporate.document,
                          callback: function ($$v) {
                            _vm.$set(_vm.corporate, "document", $$v)
                          },
                          expression: "corporate.document",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "* Nome da empresa:",
                        "label-for": "nested-name",
                        "label-cols-sm": "2",
                        "label-align-sm": "left",
                        state: _vm.validates.name,
                        "invalid-feedback": _vm.corporateNameInvalidFeedback,
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: { id: "nested-name" },
                        model: {
                          value: _vm.corporate.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.corporate, "name", $$v)
                          },
                          expression: "corporate.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "* Telefone:",
                        "label-for": "nested-phone",
                        "label-cols-sm": "2",
                        "content-cols-lg": "4",
                        "label-align-sm": "left",
                        state: _vm.validates.phone,
                        "invalid-feedback": _vm.corporatePhoneInvalidFeedback,
                      },
                    },
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: ["(##) #####-####"],
                            expression: "['(##) #####-####']",
                          },
                        ],
                        attrs: { id: "nested-phone" },
                        model: {
                          value: _vm.corporate.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.corporate, "phone", $$v)
                          },
                          expression: "corporate.phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "* Cep:",
                        "label-for": "nested-cep",
                        "label-cols-sm": "2",
                        "content-cols-lg": "4",
                        "label-align-sm": "left",
                        state: _vm.validates.cep,
                        "invalid-feedback": _vm.invalidFeedbackCEP,
                      },
                    },
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: ["#####-###"],
                            expression: "['#####-###']",
                          },
                        ],
                        attrs: { id: "nested-cep" },
                        model: {
                          value: _vm.corporate.cep,
                          callback: function ($$v) {
                            _vm.$set(_vm.corporate, "cep", $$v)
                          },
                          expression: "corporate.cep",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "* Logradouro:",
                        "label-for": "nested-address",
                        "label-cols-lg": "2",
                        "label-align-sm": "left",
                        state: _vm.validates.address,
                        "invalid-feedback": "Campo obrigatório",
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: { id: "nested-address" },
                        model: {
                          value: _vm.corporate.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.corporate, "address", $$v)
                          },
                          expression: "corporate.address",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "* Bairro:",
                        "label-for": "nested-district",
                        "label-cols-sm": "2",
                        "label-align-sm": "left",
                        state: _vm.validates.district,
                        "invalid-feedback": "Campo obrigatório",
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: { id: "nested-district" },
                        model: {
                          value: _vm.corporate.district,
                          callback: function ($$v) {
                            _vm.$set(_vm.corporate, "district", $$v)
                          },
                          expression: "corporate.district",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "* Estado:",
                        "label-for": "nested-state",
                        "label-cols-lg": "2",
                        "label-align-sm": "left",
                        state: _vm.validates.state,
                        "invalid-feedback": "Campo obrigatório",
                      },
                    },
                    [
                      _c("b-select", {
                        staticClass: "selectpicker form-control",
                        attrs: { options: _vm.states, id: "nested-state" },
                        on: { change: _vm.onStateComboChanged },
                        model: {
                          value: _vm.corporate.state,
                          callback: function ($$v) {
                            _vm.$set(_vm.corporate, "state", $$v)
                          },
                          expression: "corporate.state",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "* Cidade:",
                        "label-for": "nested-city",
                        "label-cols-lg": "2",
                        "label-align-sm": "left",
                        state: _vm.validates.city,
                        "invalid-feedback": "Campo obrigatório",
                      },
                    },
                    [
                      _c("b-select", {
                        staticClass: "selectpicker form-control",
                        attrs: { options: _vm.cities, id: "nested-city" },
                        model: {
                          value: _vm.corporate.city,
                          callback: function ($$v) {
                            _vm.$set(_vm.corporate, "city", $$v)
                          },
                          expression: "corporate.city",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("b-form-group", [
                    _c(
                      "div",
                      { staticClass: "submit-button" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { type: "submit", variant: "primary" },
                            on: { click: _vm.onSendClickButton },
                          },
                          [_vm._v(_vm._s(_vm.sendButtonText))]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "background-section-title" }, [
      _c("div", { staticClass: "section-title" }, [
        _c("h2", [_vm._v("Conta corporativa")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }